@import "elevation";

.article, .ce_text, .ce_form, .ce_headline, .mod_newslist {
  text-align: left;
  height: 100%;
  border-radius: 15px;
  padding: 20px 20px 30px;

  .article {
    background: none !important;
    border: none;
    padding: 0;
  }

  .row {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      //padding: 0;
    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}

.ce_teaser {
  @include elevation-4();
  border-radius: 15px;
  padding: 20px;

  &:hover {
    @include elevation-12();
  }
}

.invisible {
  display: none;
}

.float_right {
  float: right;
}

.float_left {
  float: left;
}

.ce_headline {
  text-align: center;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.social-teaser-bar {
  padding-top: 40px;
  padding-bottom: 40px;
}

.social-teaser {
  border-radius: 6px;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: space-between;

  @include respond-above(lg) {
    @include elevation-4();
    &:hover {
      @include elevation-12();
    }
  }

  a:hover {
    color: unset;
  }

  i, img, svg {
    max-width: 100%;
    max-height: 100%;
    margin: 1.5rem auto;
    width: 5rem !important;
    height: 5rem;
  }

  .button {
    margin: .8rem auto 2rem;
    font-size: 1.5rem;
  }

  @include respond-below(lg) {
    background-color: transparent !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    .button {
      display: none;
    }

    img {
      max-height: 100%;
    }

    &.bg-blue, &.bg-blue a {
      color: #3b5999 !important;
    }

    &.bg-red, &.bg-red a {
      color: #db2414 !important;
    }

    &.bg-bordeaux, &.bg-bordeaux a {
      color: #bd081c !important;
    }

    .footer .columns {
      display: flex;
      flex-direction: column-reverse
    }
  }
}

.fancy-links {
  a {
    @include font-family-3();
    font-size: 1.9rem;
    line-height: 1.9rem;
    color: $red;

    @include respond-below(lg) {
      a {
        font-size: 16px;
      }
    }
  }
}

/* Special content on demand of customer */
.special-content {
  .container, .container-fluid {
    @include respond-below(lg) {
      margin: 0 !important;
      padding: 0 !important;

      > .row {
        margin: 0 !important;
        padding: 0 !important;

        & > [class*="col-"] {
          margin: 0 !important;
          padding: 0 !important;
        }
      }
    }
  }

  article, .ce_form {
    padding: 60px;
    font-size: 20px;

    h1 {
      font-size: 90px;
      line-height: 84px;
      margin: 0 0 40px 0;
    }

    h2 {
      font-size: 34px;
      line-height: 40px;
      margin: 0;
    }

    h3 {
      font-size: 30px;
      line-height: 36px;
      margin: 0;
    }

    p {
      margin: 0 0 40px 0;
    }

    a {
      @include font-family-3();
      font-size: 1.9rem;
      line-height: 1.9rem;
      color: $red;
    }

    @include respond-below(lg) {
      padding: 20px;
      font-size: 14px;
      line-height: 27px;
      border-radius: 0;
      width: 100%;

      h1 {
        font-size: 36px;
        margin-bottom: 10px;
      }
      h2 {
        font-size: 26px;
        margin-bottom: 2px;
        line-height: 28px;
      }
      a {
        font-size: 16px;
      }
      .long-text a {
        font-size: 20px;
      }
      p {
        margin-bottom: 15px;
      }
    }
  }

  /* TODO: Can be outsourced later: */

  /* END TODO */
}

.mod_article_old {

  select, input[type="text"], textarea {
    display: inline-block;
    font-size: 20px;
    font-weight: normal;
    background-color: #FFF;
    border: 1px solid #CCC;
    font-family: 'Open Sans';
    border-radius: 0;
    text-transform: none;
  }

  input[type="checkbox"], input[type="radio"] {
    border: 0;
    padding: 0;
    background-color: transparent;
    outline: none;
  }

  label.select, legend {
    font-family: "LUELLA Bold";
    font-weight: normal;
    font-size: 34px;
    line-height: 40px;

    @include respond-below(lg) {
      font-size: 26px;
      margin-bottom: 2px;
      line-height: 28px;
    }
  }

  .widget {
    margin-bottom: 40px;
  }

  input[type="checkbox"] {
    width: auto;
  }

  .widget-checkbox {
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      label {
        margin-left: 3%;
        margin-bottom: 0;
      }
    }
  }

  fieldset {
    @include make-row();

    > * {
      @include make-col-ready();
    }

    .widget-text {
      @include make-col(6);
      @include respond-below(lg) {
        @include make-col(12);
      }
    }

    .widget-textarea, .widget-upload {
      @include make-col(12);
    }

    .widget {
      margin-bottom: 20px;
    }
  }
}
