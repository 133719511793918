@font-face{
  font-family:"LUELLA W04 Regular";
  src:url("../fonts/luella/2a78ca13-93a7-4bd4-a2ed-0c0198a4b4e8.eot?#iefix");
  src:url("../fonts/luella/2a78ca13-93a7-4bd4-a2ed-0c0198a4b4e8.eot?#iefix") format("eot"),url("../fonts/luella/89139888-2791-4abb-8957-7fae003a1c8e.woff2") format("woff2"),url("../fonts/luella/855a873f-e6b3-4d59-83e4-ffb61dbed63a.woff") format("woff"),url("../fonts/luella/bc64e345-7405-46d4-b0a1-56d2e12879e4.ttf") format("truetype"),url("../fonts/luella/8b5c14eb-2abc-4f5a-bbd7-a33585be99c9.svg#8b5c14eb-2abc-4f5a-bbd7-a33585be99c9") format("svg");
}
@font-face {
  font-family: "LUELLA W04 Bold";
  src: url(/files/build/fonts/luella/dfe8314c-170c-42df-a256-a7577c27c820.eot?#iefix);
  src: url(/files/build/fonts/luella/dfe8314c-170c-42df-a256-a7577c27c820.eot?#iefix) format("eot"),
  url(/files/build/fonts/luella/d6e97479-3e7c-456c-ad0f-0ebf57d00231.woff2) format("woff2"),
  url(/files/build/fonts/luella/57400263-5543-4ac9-97c8-dde59fe73cbc.woff) format("woff"),
  url(/files/build/fonts/luella/7db78558-40ff-4659-95be-1b57565f3c08.ttf) format("truetype"),
  url(/files/build/fonts/luella/975e7f93-a31b-4120-a6da-25c0997fd036.svg#975e7f93-a31b-4120-a6da-25c0997fd036) format("svg")
}

@font-face {
  font-family: "LUELLA Black W04 Regular";
  font-weight: 400;
  src: url(/files/build/fonts/luella/cfd3aa0d-3ed9-4b56-a4e7-b1cd71165af7.eot?#iefix);
  src: url(/files/build/fonts/luella/cfd3aa0d-3ed9-4b56-a4e7-b1cd71165af7.eot?#iefix) format("eot"),
  url(/files/build/fonts/luella/5ce88e03-07b2-43df-b2f6-a2d526ee9074.woff2) format("woff2"),
  url(/files/build/fonts/luella/863106b7-0fda-400a-9f1f-d20933e31acf.woff) format("woff"),
  url(/files/build/fonts/luella/e2ef7b96-f930-4239-a0df-8026ee47ce8b.ttf) format("truetype"),
  url(/files/build/fonts/luella/84992520-12e7-44b7-a58e-4d81891f4e32.svg#84992520-12e7-44b7-a58e-4d81891f4e32) format("svg")
}
@font-face {
  font-family: 'Open Sans';
  src: url('/files/build/fonts/opensans/OpenSans-Regular.eot');
  src: url('/files/build/fonts/opensans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('/files/build/fonts/opensans/OpenSans-Regular.woff2') format('woff2'),
  url('/files/build/fonts/opensans/OpenSans-Regular.woff') format('woff'),
  url('/files/build/fonts/opensans/OpenSans-Regular.ttf') format('truetype'),
  url('/files/build/fonts/opensans/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/files/build/fonts/opensans/OpenSans-Bold.eot');
  src: url('/files/build/fonts/opensans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('/files/build/fonts/opensans/OpenSans-Bold.woff2') format('woff2'),
  url('/files/build/fonts/opensans/OpenSans-Bold.woff') format('woff'),
  url('/files/build/fonts/opensans/OpenSans-Bold.ttf') format('truetype'),
  url('/files/build/fonts/opensans/OpenSans-Bold.svg#OpenSans-Regular') format('svg');
  font-weight: bold;
  font-style: normal;
}

$font-family-1: 'Open Sans';
$font-family-2: 'LUELLA Black W04 Regular';
$font-family-3: 'LUELLA W04 Bold';

@mixin font-family-1() {
  font-family: $font-family-1;
  font-weight: normal;
  font-style: normal;
}

@mixin font-family-2() {
  font-family: $font-family-2;
  font-weight: 400;
  font-style: normal;
}

@mixin font-family-3() {
  font-family: $font-family-3;
  font-weight: bold;
  font-style: normal;
}
