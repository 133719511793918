
article.datenschutz {
  p {
    margin-bottom: 10px;

    strong {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  h1 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  h2 {
    margin-top: 40px;
    margin-bottom: 30px;
  }

  h3 {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
