@import "elevation";
@import "colors";

.row.downloads-row {
  margin-right: -.65rem !important;
  margin-left: -.65rem !important;
  margin-bottom: 1rem;

  .col-12 {
    padding: .65rem !important;
  }

  .download {
    @include elevation-4();

    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    overflow: hidden;
    background-color: #f5f5f5;
    background-clip: border-box;
    -webkit-background-size: contain;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    transition: all .5s;

    .download-sizer {
      padding-bottom: 100%;
    }

    .download--overlay {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      transition: opacity .5s;
      background: rgba($orange, 0.5);
      color: $white;
      font-size: 3rem;
    }

    .icon {
      display: flex;
      width: 100%;
      height: 100%;
      position: absolute;
      padding: 3rem;
    }
  }

  a:hover {
    .download {
      @include elevation-8();

      .download--overlay {
        opacity: 1;
      }
    }
  }
}