@import "elevation";

.teaser {
  &.bg-teaser {
    background-image: url('/files/build/img/teaser-background-mobile.jpg');
  }

  &.bg-recipes {
    background-image: url('/files/build/img/rezepte-mobile.jpg');
    min-height: 620px;
    height: 620px;
    background-position: bottom center !important;
  }

  &.bg-orange-page {
    background-image: url('/files/build/img/background-orange.jpg');
  }
}

.teaser-wide {
  @include respond-above(md) {
    &.bg-teaser {
      background-image: url('/files/build/img/teaser-background-desktop.jpg');
    }
    &.bg-recipes {
      background-image: url('/files/build/img/rezepte.jpg');
      min-height: 400px;
      height: 400px;
      background-position: bottom left !important;
    }
    &.bg-orange-page {
      background-image: url('/files/build/img/background-orange.jpg');
      background-position: bottom right !important;
    }
  }
}

.teaser {
  /*@include bounceIn();*/

  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
  overflow: hidden;

  border-radius: 15px;

  /* Following for all teaser */
  background-size: cover;
  background-position: bottom left;

  .teaser-text {
    padding: 2rem 1rem 1rem;

    hr {
      text-align: left;
    }

    h2 {
      margin-bottom: 0.5rem;
    }

    p:first-of-type {
      margin-top: 1rem;
    }

    .button {
      float: right;
      margin-right: -1.25rem;
    }
  }

  .teaser-images {
    position: relative;
    display: flex;
    flex-direction: column;

    img.stack {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 1;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }

    img.teaser-image, img.teaser-image-desktop {
      position: relative;
      width: 100%;
      z-index: 9;
      margin-top: auto;
      align-self: flex-end;
    }

    img.teaser-image-desktop {
      display: none;
    }

    img.stoerer {
      position: absolute;
      left: -10px;
      top: 14%;
      width: 40%;
      z-index: 10;
    }
  }

  &.teaser-wide {
    img.teaser-image {
      /* Little hack for wide teaser, since the important part of the picture will crop to much of the bottom in the most cases */
      padding-bottom: 1.4rem;
    }
  }

  /* Following only for teaser-wide when above MD */
  @include respond-above(md) {
    &.teaser-wide {
      /* Wide teaser */
      background-image: url('/files/build/img/teaser-background-desktop.jpg');
      background-position: bottom left;

      p {
        padding-right: 10%;
      }

      .teaser-text {
        p {
          padding-right: 3rem;
        }

        .button {
          align-self: flex-end;
          float: none;
        }
      }

      img.stack {
        display: none;
      }

      img.teaser-image {
        display: none;
      }

      img.teaser-image-desktop {
        display: block;
      }
    }
  }
}

.slider .col {
  /* Workaround for bootstrap and slider (thus there is no direct row around any div inside) */
  padding-left: 0;
  padding-right: 0;
}

.slick {
  overflow: hidden;
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.slick-arrow {
  z-index: 21;

  &.slick-prev, &.slick-next {
    width: 48px;
    height: 48px;

    top: 50%;
    @include respond-above(md) {
      top: 80%;
    }
    @include respond-above(lg) {
      top: 70%;
    }

    &:before {
      font-size: 3rem !important;
    }
  }

  &.slick-prev {
    left: 2rem !important;
  }

  &.slick-next {
    right: 2rem !important;
  }
}

.slick-dots {
  @include respond-above(md) {
    left: -1rem;
  }
  bottom: 1rem !important;

  li button:before {
    color: $orange;
    font-size: 1.1rem;
  }

  li.slick-active button:before {
    color: $orange;
  }
}

.box {
  position: relative;
  padding: 50px 30px;
  border-radius: 10px;

  h2 {
    font-family: 'LUELLA Regular';
    font-weight: normal;
    font-size: 2.5rem;
    margin-bottom: 0.3rem;
    line-height: 40px;
  }

  .product-icon {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .product-hover {
    display: flex;
    flex-direction: column;
  }

  .product-image {
    padding-top: .8rem;
    padding-bottom: .8rem;
    position: relative;
    max-height: 80%;
    max-width: 100%;
  }

  .product-img {
    max-width: 100%;
    /*max-height: 420px;*/
    max-height: 100%;
    margin: 0 auto;
  }

  /* Some optimizations for different screen sizes */
  @include respond-below(sm) {
    padding: 25px;
    font-size: 15px;
    p {
      font-size: inherit;
    }

    .product-img {
      max-height: 200px;
      margin-bottom: 0 !important;
    }
  }
  @include respond-below(md) {
    p {
      font-size: 0.9rem;
    }
  }
  @include respond-above(lg) {
    p {
      font-size: inherit;
    }
  }

  .containerfade {
    position: relative;

    .hide-on-hover {
      transition: .5s ease;
    }

    .overlayfade {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: .5s ease;

      .imagefade {
        display: block;
        width: 100%;
        height: auto;
      }

      .textfade {
        color: white;
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        white-space: nowrap;
      }
    }
  }

  &:hover {
    .overlayfade {
      opacity: 1;
    }

    .hide-on-hover {
      opacity: 0;
    }
  }

  &.box-short {
    height: 302px;

    @media (max-width: 1450px) {
      height: 362px;
    }

    @media (max-width: 1250px) {
      height: 402px;
    }

    @media (max-width: 1150px) {
      height: 422px;
    }

    @include respond-below(lg) {
      height: 302px;
    }

    @media (max-width: 883px) {
      height: 362px;
    }

    @include respond-below(md) {
      height: auto;
    }
  }

  &.box-short {
    &.has-icons-right {
      h1, h2, h3, h4, h5 {
        @include respond-below(md) {
          margin-right: 50px;
        }
      }
    }
  }

  &.box-tall {
    height: 624px;

    @media (max-width: 1450px) {
      height: 744px;
    }

    @media (max-width: 1250px) {
      height: 824px;
    }

    @media (max-width: 1150px) {
      height: 864px;
    }

    @include respond-below(lg) {
      height: 630px;
    }

    @media (max-width: 883px) {
      height: 746px;
    }

    @include respond-below(md) {
      height: auto;
    }
  }
}

a .box {
  @include elevation-4();

  &:hover {
    @include elevation-12();
  }
}


