@import 'fonts';
@import 'functions';

.download-title, .download-item {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.download-title {
  margin: 40px 0 10px 0;

  h2 {
    @include font-family-2();
    font-weight: normal;
    font-size: 60px;
    line-height: 80px;
    padding: 10px 20px;
    margin: 0;

    width: 100%;
    text-align: center;

    @include respond-below(md) {
      font-size: 40px;
      line-height: 60px;
    }
  }
}

.download-item {
  padding: 30px;

  h3 {
    @include font-family-3();
    font-size: 27px;
    font-weight: normal;
    line-height: 32px;

    margin: 0;

    width: 100%;
    text-align: center;

    @include respond-below(md) {
      font-size: 35px;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    max-height: 300px;
  }

  ul {
    list-style: none;
    padding: 0;
  }
}

.download-item-2 {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  padding: 30px;

  .icon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    max-width: 64%;
  }

  .preview-img {
    align-self: flex-start;
    margin: 0 auto;
    height: 150px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      margin: 0 auto;
      max-height: 150px;
      max-width: 100%;
    }
  }

  h3 {
    @include font-family-3();
    font-size: 27px;
    font-weight: normal;
    line-height: 32px;

    margin: 0;

    width: 100%;
    text-align: center;

    @include respond-below(md) {
      font-size: 35px;
    }
  }

  .information {
    margin: 1rem 0;
  }

  a {
    color: $red;
  }
}

.mediathek {
  h1 {
    font-size: 90px;
    line-height: 84px;
    margin: 40px 0;
  }
}

.card {
  width: 100%;
  padding: 10px 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  background-color: rgba(255, 255, 255, 0.85);

  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: space-between;

  h2 {
    @include font-family-2();
    font-weight: normal;
    font-size: 60px;
    line-height: 80px;
  }

  h3 {
    @include font-family-2();
    font-size: 45px;
    font-weight: normal;
    line-height: 1.2;
  }

  @include respond-below(md) {
    h3 {
      font-size: 35px;
    }
  }

  h4 {
    @include font-family-2();
    font-weight: normal;
  }

  hr {
    height: 5px;
    background: url(/files/build/img/linie.png) no-repeat center;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  @include respond-below(md) {
    h2 {
      font-size: 40px;
      line-height: 60px;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    max-height: 300px;
  }

}
