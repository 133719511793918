/* double img zooms*/
.double-img-zoom-1,
.double-img-zoom-2,
.double-img-zoom-3,
.double-img-zoom-4 {
  width: 230px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;

  float: left;
  margin-bottom: 1rem;
  margin-right: 1rem;

  @media (max-width: 767px) {
    order: 12;
    display: flex;
    flex-direction: column;
    height: auto;
    align-self: center;
  }
}

.double-img-zoom-1 img.first-zoom-img,
.double-img-zoom-1 img.second-zoom-img,
.double-img-zoom-2 img.first-zoom-img,
.double-img-zoom-2 img.second-zoom-img,
.double-img-zoom-3 img.first-zoom-img,
.double-img-zoom-3 img.second-zoom-img,
.double-img-zoom-4 img.first-zoom-img,
.double-img-zoom-4 img.second-zoom-img {
  width: 100%;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.double-img-zoom-1 img.first-zoom-img,
.double-img-zoom-1 img.second-zoom-img {
  transform-origin: 88% 0%;
}

.double-img-zoom-2 img.first-zoom-img,
.double-img-zoom-2 img.second-zoom-img {
  transform-origin: 10% 60%;
}

.double-img-zoom-2.medium img.first-zoom-img,
.double-img-zoom-2.medium img.second-zoom-img {
  transform-origin: 9% 75%;
}

.double-img-zoom-2.large img.first-zoom-img,
.double-img-zoom-2.large img.second-zoom-img {
  transform-origin: 10% 85%;
}

.double-img-zoom-3 img.first-zoom-img,
.double-img-zoom-3 img.second-zoom-img {
  transform-origin: 100% 87%;
}

.double-img-zoom-4 img.first-zoom-img,
.double-img-zoom-4 img.second-zoom-img {
  transform-origin: 10% 60%;
}

.double-img-zoom-1 img.second-zoom-img,
.double-img-zoom-2 img.second-zoom-img,
.double-img-zoom-3 img.second-zoom-img,
.double-img-zoom-4 img.second-zoom-img {
  opacity: 0;
  width: 100%;
  position: absolute;
  /*top: 0;*/
}

.double-img-zoom-1:hover img.first-zoom-img,
.double-img-zoom-1:hover img.second-zoom-img {
  -moz-transform: scale(3);
  -webkit-transform: scale(3);
  transform: scale(3);
}

.double-img-zoom-2:hover img.first-zoom-img,
.double-img-zoom-2:hover img.second-zoom-img {
  -moz-transform: scale(3.35);
  -webkit-transform: scale(3.35);
  transform: scale(3.35);
}

.double-img-zoom-2.medium:hover img.first-zoom-img,
.double-img-zoom-2.medium:hover img.second-zoom-img {
  -moz-transform: scale(4.5);
  -webkit-transform: scale(4.5);
  transform: scale(4.5);
}

.double-img-zoom-2.large:hover img.first-zoom-img,
.double-img-zoom-2.large:hover img.second-zoom-img {
  -moz-transform: scale(3.45);
  -webkit-transform: scale(3.45);
  transform: scale(3.45);
}

.double-img-zoom-3:hover img.first-zoom-img,
.double-img-zoom-3:hover img.second-zoom-img {
  -moz-transform: scale(2.55);
  -webkit-transform: scale(2.55);
  transform: scale(2.55);
}

.double-img-zoom-4:hover img.first-zoom-img,
.double-img-zoom-4:hover img.second-zoom-img {
  -moz-transform: scale(3.35);
  -webkit-transform: scale(3.35);
  transform: scale(3.35);
}

.double-img-zoom-1:hover img.second-zoom-img,
.double-img-zoom-2:hover img.second-zoom-img,
.double-img-zoom-3:hover img.second-zoom-img,
.double-img-zoom-4:hover img.second-zoom-img {
  opacity: 1;
}

.double-img-zoom-2:hover img.first-zoom-img,
.double-img-zoom-3:hover img.first-zoom-img,
.double-img-zoom-4:hover img.first-zoom-img {
  opacity: 0;
}