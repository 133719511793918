// Colors

$black: hsl(0, 0%, 4%);
$black-bis: hsl(0, 0%, 7%);
$black-ter: hsl(0, 0%, 14%);

$grey-darker: hsl(33, 54%, 19%);
$grey-dark: hsl(33, 54%, 29%);
$grey: hsl(33, 54%, 40%);
$grey-light: hsl(0, 0%, 71%);
$grey-lighter: hsl(0, 0%, 86%);

$white-ter: hsl(0, 0%, 96%);
$white-bis: hsl(0, 0%, 98%);
$white: hsl(0, 0%, 100%);

$orange: rgb(239, 125, 0);
$yellow: #f9ae17;
$green: hsl(141, 71%, 48%);
$turquoise: hsl(141, 71%, 48%);
$cyan: hsl(204, 86%, 53%);
$blue: #3b5999;
$purple: #bd081c;
$red: #db2414;
$red-light: #e41c19;
$brown: #A37C59;