#cookie-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 300;
  background-color: rgba(0, 0, 0, 0.85);
  height: auto;
  color: #FFF;
  font-size: 20px;
  line-height: 27px;
  color: #eeeeee;
  text-align: center;
  padding: 20px;

  p {
    padding: 0;
    max-width: 890px;
    margin: 0 auto;
  }

  a {
    color: #ffffff;
    display: inline-block;
    border-radius: 3px;
    text-decoration: none;
    padding: 0 6px;
    margin-left: 8px;
  }

  .cb-enable {
    display: inline-block;
    border: 1px solid #FFF;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 20px;
    margin-top: 13px;
  }

  .cb-policy {
    display: inline-block;
    border: 1px solid #FFF;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 20px;
    margin-top: 13px;
  }
}

#cookie-bar.fixed {
  position: fixed;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
}

#cookie-bar .cb-enable {
  background: #007700;
}

#cookie-bar .cb-enable:hover {
  background: #009900;
}

#cookie-bar .cb-disable {
  background: #990000;
}

#cookie-bar .cb-disable:hover {
  background: #bb0000;
}

#cookie-bar .cb-policy {
  background: #0033bb;
}

#cookie-bar .cb-policy:hover {
  background: #0055dd;
}