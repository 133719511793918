@import "elevation";

.recipe {
  margin-bottom: 4rem;
  font-size: 16px;

  .recipe-text {
    @include elevation-8();
    z-index: 3;
    border-radius: 10px;
    padding: 30px;
  }

  .recipe-image {
    z-index: 4;

    @include respond-below(md) {
      margin-bottom: -30px;
    }

    .embed-responsive {
      background-color: white;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;

      .embed-responsive-item {
        padding: 1rem;
      }
    }
  }

  .recipe-title {
    &.d-md-none {
      text-shadow: 1px 0px 6px #fff;
    }
  }

  .polaroid-shadow {
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.6);
    z-index: 900;
  }

  .polaroid {
    &.rotate-left {
      transform: rotate(-1deg);
      -moz-transform: rotate(-1deg);
      -webkit-transform: rotate(-1deg);
      -ms-transform: rotate(-1deg);
    }

    &.rotate-right {
      transform: rotate(1deg);
      -moz-transform: rotate(1deg);
      -webkit-transform: rotate(1deg);
      -ms-transform: rotate(1deg);
    }

    @media screen and (min-width: 679px) {
      &.rotate-left {
        transform: rotate(-4deg);
        -moz-transform: rotate(-4deg);
        -webkit-transform: rotate(-4deg);
        -ms-transform: rotate(-4deg);
      }

      &.rotate-right {
        transform: rotate(4deg);
        -moz-transform: rotate(4deg);
        -webkit-transform: rotate(4deg);
        -ms-transform: rotate(4deg);
      }
    }
  }

  .recipe-hint {
    @include elevation-8();
    border-radius: 10px;
    padding: 30px;

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}

.bg-paper {
  background: url("/files/build/img/bg-paper.jpg");
  background-repeat: repeat-y;
  -webkit-background-size: cover;
  background-size: cover;
}
