@import "colors";
@import "functions";

.footer {
  background: $brown url('/files/build/img/background_footer.jpg') no-repeat;
  -webkit-background-size: cover;
  background-size: cover;

  padding: .5rem 0;
  margin: 0;
  width: 100%;
  font-weight: bold;

  .col-12 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  a {
    padding-left: 15px;
    font-size: 16px;

    @include respond-below(md) {
      text-align: center;
      display: block;
      padding-left: 0;
      width: 100%;
    }
  }

  .mod_customnav {
    @include respond-above(md) {
      float: right;
    }
  }
}
