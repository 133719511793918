@import 'functions';
@import 'typography';

$carousel-desktop-height: 982px;
$carousel-desktop-switch: 1190px;

/* Common */
i.fresh {
  position: absolute;
  top: -8px;
  right: 0;
  background: url('/files/build/img/icon_products_cool-products-small.png') no-repeat;
  width: 20px;
  height: 20px;
}

.level-item {
  position: relative;
}

.background-image {
  width: 100%;
  position: relative;
  padding: 0 !important;
  background-color: transparent;
  background-size: cover;
  background-position: top left;
  background-repeat: no-repeat;
  height: auto;

  .product-table {
    position: absolute;
    background-color: transparent;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  .subnav {
    flex-grow: 0;
  }
}

.theta-container {
  position: relative;
  margin: 0 auto !important;
  padding: 0 !important;
  z-index: 10;
  flex-grow: 1;

  .theta-carousel-inner-container {
    width: 100%;
    height: 100%;
    position: absolute !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;

    .texte {
      position: absolute;
      top: 30px;
      left: 30px;
      width: 45%;
      z-index: 100;

      .texte-box {
        background-color: rgba(255, 255, 255, 0.85);
        border-radius: 10px;
        padding: 37px;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 20px;
      }

      h1 {
        font-size: 46px;
        @include font-family-2();
        margin: 0 0 20px 0;
        font-weight: normal;
        line-height: 50px;
      }

      .title {
        font-size: 46px;
        @include font-family-2();
        margin-bottom: 20px;
        margin-top: 40px;
        line-height: 50px;
      }
    }

    .ex-item {
      position: absolute;
      opacity: 0;

      &.theta-current-item {
        opacity: 1 !important;

        .product-fruits {
          opacity: 1;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        }

        .product-icon {
          opacity: 1;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
          z-index: 18;
        }
      }

      .product-image-wrper {
        z-index: 12;
        display: inline-flex;
        position: relative;
        align-items: center;
        margin: 0 auto;
        flex-direction: column;
        justify-content: flex-end;

        .product-image {
          z-index: 13;
          display: inline-flex;
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .product-fruits {
      position: absolute;
      right: 100%;
      bottom: 0;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      -webkit-transition: opacity 1.2s;
      transition: opacity 1.2s;
      margin-right: -35%;
    }

    .product-icon {
      position: absolute;
      cursor: pointer;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      -webkit-transition: opacity 1.2s;
      transition: opacity 1.2s;
    }

    .badge-icon {
      position: absolute;
    }

    .product-info {
      position: absolute;
      display: none;
      width: 363px !important;
      background-color: #FFF;
      padding: 40px;
      border-radius: 10px;
      font-size: 14px;
      line-height: 21px;
      color: #333;
      background: url("/files/build/img/product_tooltips-top.png") center top no-repeat, url("/files/build/img/product_tooltips-bottom.png") center bottom no-repeat;

      z-index: 200;

      h3 {
        font-size: 30px;
        line-height: 35px;
        color: #d10b11;
      }

      h4 {
        color: #d10b11;
      }

      .product-info-text {
        overflow: hidden;
      }

      .box-naehrwerte .product-info-text {
        overflow-y: auto;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        max-height: 700px;
      }

      .middle {
        z-index: -1;
        position: absolute;
        top: 82px;
        right: 0;
        bottom: 49px;
        left: 0;
        background: url("/files/build/img/product_tooltips-middle.png") center center repeat-y;
      }

      .close-product-info {
        position: absolute;
        right: 19px;
        top: 16px;
        cursor: pointer;
        width: 30px;
      }
    }

    .show-product-box {
      position: absolute;
      z-index: 100;

      img {
        cursor: pointer;
      }

      .show-product {
        &.prev {
        }

        &.next {
        }
      }
    }

    .click-area {
      z-index: 50;
    }
  }
}

.product-mobile {
  display: none;
}


/* Mobile */
$carousel-mobile-switch: $carousel-desktop-switch - 1px;
@media only screen and (max-width: $carousel-mobile-switch) {
  .background-image {
    .product-table {
      height: 170px;
    }
  }
  .theta-container {
    width: 100%;
    height: 400px;

    .theta-carousel-inner-container {
      display: flex;
      justify-content: center;
      width: 100% !important;
      height: 400px !important;

      #product-slider, .texte, .product-icon, .theta-container .product-info, .click-area {
        display: none !important;
      }

      .ex-item {
        .product-fruits {
          display: none;
          width: 230px;
          right: auto;
          left: 50%;
          bottom: 20px;
          margin-left: -230px;
        }

        &:not(&.theta-current-item) {
          opacity: 0 !important;
        }

        width: 100%;
        height: 100%;
        -webkit-transform: none !important;
        transform: none !important;
        margin: 0;

        .product-image-wrper {
          height: 100%;
          width: 100%;
        }
      }

      .badge-icon {
        top: 20px;
        right: 20px;
        width: 85px;
        z-index: 999;
      }

      .show-product-box {
        width: 100%;
        padding: 0 20px;
        top: 318px;
        left: 0;

        .next {
          margin-left: 0;
          position: absolute;
          top: 0;
          right: 20px;
        }
      }

      .produkt-sorte .product-table {
        height: 170px;
      }

      .produkt-sorte .sortiment a {
        margin: 0 20px !important;
      }
    }
  }

  .product-mobile {
    display: block;

    .texts-mobile {
      padding: 50px 25px 25px 25px;

      h1 {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 15px;
      }

      h3 {
        color: #333;
        margin-top: 30px;
        line-height: 26px;
        font-weight: normal;
      }

      h4 {
        @include font-family-2();
        color: #333;
        font-size: 18px;
        margin: 0 0 15px 0;
        font-weight: normal;
      }

      p {
        @include font-family-1();
        margin: 0 0 10px 0;
        font-weight: 300;
      }

      strong {
        font-size: 20px;
        line-height: 22px;
        @include font-family-2();
        font-weight: normal;
      }

      .accordion {
        .accordion-header {
          @include font-family-2();
          font-size: 30px;
          color: #d10b11;

          display: flex;
          align-items: center;

          border-bottom: 1px solid #CCC;

          &:first-child {
            border-top: 1px solid #CCC;
          }

          &.active {
            background-color: #EEE;
            margin: -1px -25px 0 -25px;
            padding: 5px 25px;
            border-top: 1px solid #CCC;
            border-bottom: 1px solid #CCC;
          }

          img {
            margin-left: -15px;
            margin-right: 10px;
            vertical-align: middle;
            border: 0;
          }
        }

        .collapse {
          display: none;
          color: #333;
          font-size: 14px !important;
          line-height: 18px;
          padding: 25px 0;
          background-color: #FFF;

          &.show {
            display: block !important;
          }
        }
      }
    }
  }
}

/* Desktop */
@media only screen and (min-width: $carousel-desktop-switch) {
  .background-image {
    min-height: $carousel-desktop-height;
    background-position-y: -130px;

    .product-table {
      height: 340px;
    }

    .subnav {
      height: auto;
    }
  }

  .theta-container {
    width: 1260px;
    height: 890px;

    .theta-carousel-inner-container {
      width: 1260px !important;
      height: 890px !important;

      .ex-item {
        .product-image-wrper {
          height: 600px;
          width: 400px;
        }

        .show-product-box {
          top: 779px;
          left: 696px;
        }

        /*
                .naehrwerte {
                  top: 32px;
                  left: 80px;
                }

                .saftqualitaet {
                  top: 256px;
                  left: 224px;
                }

                .sorte {
                  top: 351.4px;
                  left: 11.2px;
                }

                .vegan {
                  top: 494px;
                  left: 214px;
                }*/
      }

      .product-info {
        z-index: 999;

        &.box-naehrwerte {
          /*top: 16px;
          left: 816px;*/
          width: 380px;
          padding-right: 26px;
          padding-top: 55px;

          .product-info-text {
            max-height: 651px;
            overflow: auto;
          }
        }

        &.box-naehrwerte .arrow {
          position: absolute;
          left: -29px;
          top: 121px;
        }

        &.box-sorte {
          /*top: 464px;
          left: 720px;*/

          .product-info-text {
            max-height: 230px;
            overflow: auto;
          }
        }

        &.box-sorte .arrow {
          position: absolute;
          left: -29px;
          top: 41px;
        }

        &.box-saftqualitaet {
          /*top: 356.8px;
          left: 464px;*/

          .product-info-text {
            max-height: 400px;
            overflow: auto;
          }
        }

        &.box-saftqualitaet .arrow {
          position: absolute;
          right: -29px;
          top: 2.6rem;
        }

        &.box-vegan {
          /*top: 635px;
          left: 451px;*/

          .product-info-text {
            max-height: 170px;
            overflow: auto;
          }
        }

        &.box-vegan .arrow {
          position: absolute;
          right: -29px;
          top: 36px;
        }
      }

      .badge-icon {
        position: absolute;
        top: 50px; // was 690px
        left: 1050px;
        height: auto;
        width: 160px;
      }

      .show-product-box {
        position: absolute;
        bottom: 47px;
        left: 671px;

        .next {
          margin-left: 192px;
        }
      }

      .click-area {
        position: absolute;
        top: 11.2rem;
        left: 57.5rem;
        cursor: pointer;

        div {
          position: absolute;
        }

        .area-1 {
          width: 110px;
          height: 524px;
          top: 0;
          left: 0;
        }

        .area-2 {
          width: 99px;
          top: 3.2rem;
          height: 425px;
          left: 7rem;
        }

        .area-3 {
          width: 69px;
          top: 5.6rem;
          height: 348px;
          left: 13rem;
        }

        .area-4 {
          width: 49px;
          top: 8.8rem;
          height: 270px;
          left: 17.6rem;
        }
      }
    }
  }
}

// Safari Fix:
.safari .produkt-sorte .product-info {
  -webkit-transform-origin: -40% bottom;
  -webkit-transform: translateZ(30px) scale(0.95);
  -webkit-font-smoothing: subpixel-antialiased;
}

.safari .produkt-sorte .box-naehrwerte {
  top: -13px;
}

.safari .produkt-sorte .texte {
  -webkit-transform-origin: right 350px 0;
  -webkit-transform: translate3d(2px, 0, 30px) scale(0.95);
  -webkit-font-smoothing: subpixel-antialiased;
}
