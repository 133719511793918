.ce_gallery {
  ul {
    position: relative;
    display: flex;

    width: 100%;
    max-width: 100%;
    flex-wrap: wrap;

    list-style: none;

    margin: 0;
    padding: 0;

    li {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
      list-style: none;

      .image_container {
        width: 100%;

        a {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;

          img {
            display: inline-flex;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      &.col_first {


      }

      &.col_last {
        padding-right: 0;
      }

      &.row_last {
        margin-bottom: 0;
      }

      @include respond-above(md) {
        padding-right: $spacer;

        &.row_last {
          figure.image_container {
            margin-bottom: 0;
          }
        }
      }
    }

    @include respond-above(md) {
      &.cols_4 {
        li {
          width: 25%;
          max-width: 25%;
          flex-basis: 25%;
        }
      }
      &.cols_3 {
        li {
          width: 33.333%;
          max-width: 33.333%;
          flex-basis: 33.333%;
        }
      }
      &.cols_2 {
        li {
          width: 50%;
          max-width: 50%;
          flex-basis: 50%;
        }
      }
    }
  }
}

