@mixin make-button() {
  -moz-appearance: none;
  -webkit-appearance: none;
  position: relative;
  display: inline-flex;
  border: none;
  padding: 0.6rem 1.1rem;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  background-color: transparent;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

@mixin make-orange-button() {
  @include make-button();

  background-image: url(/files/build/img/buttons/orange.png);
  color: #fff;

  &:hover {
    background-image: url(/files/build/img/buttons/red.png);
  }
}


button {
  -moz-appearance: none;
  -webkit-appearance: none;
}

.button, .orange-button {
  @include make-orange-button();
}

.red-button {
  background-image: url(/files/build/img/buttons/red.png) !important;
  color: #fff;

  &:hover {
    background-image: url(/files/build/img/buttons/orange.png) !important;
  }
}

.instagram-button {
  background-image: url(/files/build/img/buttons/instagram.png) !important;
  color: #fff;
}

.white-button {
  background-image: url(/files/build/img/buttons/white.png) !important;
  color: #f5b339;
}

.hamburger {
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  position: relative;
  display: block;
  width: 51px;
  height: 51px;
  margin-top: -8px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .burger-main {
    position: absolute;
    padding: 20px 16px;
    height: 68px;
    width: 68px;

    &:hover {
      color: $red !important;
    }

    .burger-inner {
      position: relative;
      height: 28px;
      width: 36px;

      span {
        position: absolute;
        display: block;
        height: 4px;
        width: 36px;
        border-radius: 2px;
        background: #4b3316;
      }

      .top {
        top: 0;
        transform-origin: 34px 2px;
      }

      .bot {
        bottom: 0;
        transform-origin: 34px 2px;
      }

      .mid {
        top: 12px;
      }
    }
  }

  .svg-main {
    position: absolute;
    top: 0;
    left: 0;
    width: 68px;
    height: 68px;

    .svg-circle {
      height: 100%;
    }
  }

  .circle {
    width: 68px;
    height: 68px;
  }

  .path {
    stroke-dasharray: 240;
    stroke-dashoffset: 240;
    stroke-linejoin: round;
  }

  &.open {
    .path {
      animation: dash-in 0.6s linear normal;
      animation-fill-mode: forwards;
    }

    .top {
      animation: close-top-out 0.6s linear normal;
      animation-fill-mode: forwards;
    }

    .mid {
      animation: burger-fill-out 0.6s linear normal;
      animation-fill-mode: forwards;
    }

    .bot {
      animation: close-bot-out 0.6s linear normal;
      animation-fill-mode: forwards;
    }

    .animate-path {
      animation: circle-in 0.6s linear normal;
      animation-fill-mode: forwards;
    }
  }

  &.closed {
    .path {
      animation: dash-out 0.6s linear normal;
      animation-fill-mode: forwards;
    }

    .top {
      animation: close-top-in 0.6s linear normal;
      animation-fill-mode: forwards;
    }

    .mid {
      animation: burger-fill-in 0.6s linear normal;
    }

    .bot {
      animation: close-bot-in 0.6s linear normal;
      animation-fill-mode: forwards;
    }

    .animate-path {
      animation: circle-out 0.6s linear normal;
      animation-fill-mode: forwards;
    }
  }

  .path-burger {
    position: absolute;
    top: 0;
    left: 0;
    height: 68px;
    width: 68px;
    -webkit-mask: url(#mask);
    mask: url(#mask);
    -webkit-mask-box-image: url(/files/build/img/buttons/mask.svg);
  }

  .animate-path {
    position: absolute;
    top: 0;
    left: 0;
    width: 68px;
    height: 68px;
  }

  .path-rotation {
    height: 34px;
    width: 34px;
    margin: 34px 34px 0 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;

    &:before {
      content: '';
      display: block;
      width: 30px;
      height: 34px;
      margin: 0 4px 0 0;
      background: #4b3316;
    }
  }
}

@-webkit-keyframes dash-in {
  0% {
    stroke-dashoffset: 240;
  }

  40% {
    stroke-dashoffset: 240;
  }

  100% {
    stroke-dashoffset: 0;
  }

}

@keyframes dash-in {
  0% {
    stroke-dashoffset: 240;
  }

  40% {
    stroke-dashoffset: 240;
  }

  100% {
    stroke-dashoffset: 0;
  }

}

@-webkit-keyframes dash-out {
  0% {
    stroke-dashoffset: 0;
  }

  40% {
    stroke-dashoffset: 240;
  }

  100% {
    stroke-dashoffset: 240;
  }

}

@keyframes dash-out {
  0% {
    stroke-dashoffset: 0;
  }

  40% {
    stroke-dashoffset: 240;
  }

  100% {
    stroke-dashoffset: 240;
  }

}

@keyframes close-top-out {
  0% {
    left: 0;
    top: 0;
    transform: rotate(0deg);
  }

  20% {
    left: 0;
    top: 0;
    transform: rotate(15deg);
  }

  80% {
    left: -5px;
    top: 0;
    transform: rotate(-60deg);
  }

  100% {
    left: -5px;
    top: 1px;
    transform: rotate(-45deg);
  }

}

@keyframes close-bot-out {
  0% {
    left: 0;
    transform: rotate(0deg);
  }

  20% {
    left: 0;
    transform: rotate(-15deg);
  }

  80% {
    left: -5px;
    transform: rotate(60deg);
  }

  100% {
    left: -5px;
    transform: rotate(45deg);
  }

}

@keyframes close-top-in {
  0% {
    left: -5px;
    bot: 0;
    transform: rotate(-45deg);
  }

  20% {
    left: -5px;
    bot: 0;
    transform: rotate(-60deg);
  }

  80% {
    left: 0;
    bot: 0;
    transform: rotate(15deg);
  }

  100% {
    left: 0;
    bot: 1px;
    transform: rotate(0deg);
  }

}

@keyframes close-bot-in {
  0% {
    left: -5px;
    transform: rotate(45deg);
  }

  20% {
    left: -5px;
    transform: rotate(60deg);
  }

  80% {
    left: 0;
    transform: rotate(-15deg);
  }

  100% {
    left: 0;
    transform: rotate(0deg);
  }

}

@keyframes burger-fill-in {
  0% {
    width: 0;
    left: 36px;
  }

  40% {
    width: 0;
    left: 40px;
  }

  80% {
    width: 36px;
    left: -6px;
  }

  100% {
    width: 36px;
    left: 0px;
  }

}

@keyframes burger-fill-out {
  0% {
    width: 36px;
    left: 0px;
  }

  20% {
    width: 42px;
    left: -6px;
  }

  40% {
    width: 0;
    left: 40px;
  }

  100% {
    width: 0;
    left: 36px;
  }

}

@keyframes circle-out {
  0% {
    transform: rotate(0deg);
  }

  40% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }

}

@keyframes circle-in {
  0% {
    transform: rotate(360deg);
  }

  40% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0deg);
  }

}
