.bg-blue {
  color: white;

  a, h1, h2, h3, h4, h5, h6 {
    color: white;
  }

  background-color: #3b5999;
}

.bg-white-transparent-light {
  background: rgba(255, 255, 255, .5);
}

.bg-bordeaux {
  color: white;

  a, h1, h2, h3, h4, h5, h6 {
    color: white;
  }

  background-color: #bd081c;
}

.bg-red {
  color: white;

  a, h1, h2, h3, h4, h5, h6 {
    color: white;
  }

  h3 {
    text-shadow: 1px 2px 3px #443F3D;
  }

  background-color: #e41c19;
}

.bg-solid-white {
  background-color: #fff;
}

.bg-white, .bg-white-transparent {
  background-color: rgba(255, 255, 255, 0.9);
}

.ce_form {
  background-color: rgba(255, 255, 255, 0.9);
}

.bg-fresh {
  background: url('/files/build/img/card_background_fresh.png') center top no-repeat rgba(255, 255, 255, .85);
  -webkit-background-size: cover;
  background-size: cover;
}

.bg-orange {
  background-color: #eb690b;
  color: white;

  a, h1, h2, h3, h4, h5, h6 {
    color: white;
  }
}

.bg-orange-light, .bg-yellow {
  background-color: #f9ae17;
  color: white;

  a, h1, h2, h3, h4, h5, h6 {
    color: white;
  }
}

.bg-wood {
  background: url('/files/build/img/background_wood.jpg') center top no-repeat;
  -webkit-background-size: cover;
  background-size: cover;

  color: white;

  a, h1, h2, h3, h4, h5, h6 {
    color: white;
  }
}

a:hover {
  .bg-wood, .bg-orange-light, .bg-yellow, .bg-orange {
    color: white !important;
  }
}

img.bg {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1024px) { /* Specific to this particular image */
  img.bg {
    left: 50%;
    margin-left: -512px; /* 50% */
  }
}