@import "colors";
@import "elevation";

$grid-gutter-width: 1.3rem;
$spacer: 1.3rem;
$grid-breakpoints: (
        xs: 0,
        sm: 480px,
        md: 679px,
        lg: 1023px,
        xl: 1200px
);
$container-max-widths: (
        sm: 100%,
        md: 100%,
        lg: 920px,
        xl: 921px
);
$embed-responsive-aspect-ratios: ();
$embed-responsive-aspect-ratios: join(
                        (
                                        (21 9),
                                        (16 9),
                                        (3 4),
                                        (1 1),
                        ),
                $embed-responsive-aspect-ratios
);

@import './../../../node_modules/bootstrap/scss/bootstrap-reboot';
@import './../../../node_modules/bootstrap/scss/bootstrap-grid';
@import './../../../node_modules/bootstrap/scss/mixins';
@import './../../../node_modules/bootstrap/scss/utilities/text';
@import './../../../node_modules/bootstrap/scss/utilities/position';
@import './../../../node_modules/bootstrap/scss/utilities/sizing';
@import './../../../node_modules/bootstrap/scss/utilities/embed';
@import './../../../node_modules/bootstrap/scss/modal';
@import './../../../node_modules/bootstrap/scss/pagination';
@import './../../../node_modules/bootstrap/scss/close';
@import "functions";
@import "grid";
@import "fonts";
@import "typography";
@import "navbar";
@import "content";
@import "footer";
@import "backgrounds";
@import "valensina_teaser";
@import "button";
@import "input";
@import "image_zoom";
@import "news";
@import "download";
@import "valensina_recipes";
@import "valensina_products";
@import "valensina_teaser";
@import "cookiebar";
@import "downloads";
@import "elements/ce_gallery";
@import "data-protection";
@import "shadow";

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-4by3,
.embed-responsive-16by9 {
  & > .uc-embedding-container {
    margin-top: -75% !important;
  }
}


button.close {
  h1 {
    margin: 0 !important;
    line-height: 0.5;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.pagination {
  .page-link {
    text-decoration: none;
    font-family: "LUELLA Black W04 Regular";
    font-size: 20px !important;
    line-height: 1.25 !important;
  }

  .page-item.active .page-link {
    background-color: #db2414;
    border-color: #db2414;
  }
}
