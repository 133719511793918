$grey-lighter: #333;
$orange: #f9ae17;
$oange-dark: #eb690b;
$red: red;
$white: white;


button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

button, input {
  overflow: visible;
}

label {
  display: inline;
}

select {
  margin-top: 1rem;
  word-wrap: normal;
  display: inline-block;
  width: 100%;
  height: calc(2.875rem + 2px);
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin-bottom: 0;
  line-height: 1.5;
  vertical-align: middle;
  padding: .375rem 1.75rem .375rem .75rem;
  border: 1px solid $grey-lighter;
  border-radius: .25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  text-transform: none;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
  background-color: #fff;
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 10px;
  width: 100%;

  input {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
  }

  .input-group-append {
    position: relative;
    z-index: 10;
    display: -ms-flexbox;
    display: flex;
    margin-left: -2rem;

    button {
      background: transparent;
      border: none;
      z-index: 5;
      padding: 0;
    }

    .input-group-text {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 0;
      text-align: center;
      white-space: nowrap;
      border-radius: .25rem;
    }
  }
}

.widget-submit, .widget-checkbox {
  margin: 2rem 0 0;
}

.widget-text, .widget-textarea {
  margin: 2rem 0 0;
  position: relative;

  input, textarea {
    display: inline-block;
    position: relative;
    z-index: 10;
    width: 100%;
    color: #333;
    box-sizing: border-box;
    letter-spacing: 1px;
    border: 1px solid #ccc;
    padding: 7px 14px;
    transition: 0.4s;
    background: transparent;

    &:focus {
      outline: none;
    }

    &:required, &:invalid {
      box-shadow: none;
    }
  }

  label {
    position: absolute;
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    /*font-weight: 700;*/
    left: 14px;
    width: 100%;
    top: 10px;
    color: #aaa;
    transition: 0.3s;
    z-index: 1;
    letter-spacing: 0.5px;

    & ~ input::placeholder,
    & ~ textarea::placeholder {
      color: transparent !important;
    }
  }

  .focus-border {
    &:before, &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 2px;
      background-color: $orange;
      transition: 0.2s;
      transition-delay: 0.2s;
    }

    &:after {
      top: auto;
      bottom: 0;
      right: auto;
      left: 0;
      transition-delay: 0.6s;
    }

    i {
      &:before, &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 0;
        background-color: $orange;
        transition: 0.2s;
      }

      &:after {
        left: auto;
        right: 0;
        top: auto;
        bottom: 0;
        transition-delay: 0.4s;
      }
    }
  }

  &.active, &.has-content, &.has-fixed-content {
    label {
      top: -18px;
      left: 0;
      font-size: 12px;
      color: $orange;
      transition: 0.3s;
    }
  }

  &.active, &.has-content {
    .focus-border:before,
    .focus-border:after {
      width: 100%;
      transition: 0.2s;
      transition-delay: 0.6s;
    }

    .focus-border:after {
      transition-delay: 0.2s;
    }

    .focus-border {
      i:after, i:before {
        height: 100%;
        transition: 0.2s;
      }

      i:after {
        transition-delay: 0.4s;
      }
    }
  }

  &.is-invalid {
    .focus-border:before,
    .focus-border:after {
      width: 100%;
      transition: 0.2s;
      transition-delay: 0.6s;
      background-color: red;
    }

    .focus-border:after {
      transition-delay: 0.2s;
    }

    .focus-border {
      i:after, i:before {
        height: 100%;
        transition: 0.2s;
        background-color: red;
      }

      i:after {
        transition-delay: 0.4s;
      }
    }

    label {
      color: red;
    }
  }
}

/* For the search field */
.search-field {
  &.input-group, .input-group {
    input {
      background: transparent;
      border: 2px solid #724e22;
      border-radius: 12px;
      padding: 0 0.7rem;

      &:hover {
        background-color: rgba(255, 255, 255, .5);
      }

      &:focus {
        background-color: white;
      }
    }
  }
}

.nav-desktop-search {
  form {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .input-group {
    width: 60%;
  }
}

#mobile-search {
  .input-group {
    padding: 3rem;

    input {
      padding: 0 0.7rem;
    }

    .input-group-append {
      button {
        &:hover {
          color: $red;
        }
      }
    }
  }

}


/* For age verification */
.age_verification {
  #birthday {
    background-color: $white;
  }
}

.ce_form {
  a {
    @include font-family-3();
    font-size: 1.1rem;
    color: $red;
  }

  .widget-select {
    margin: 2rem 0 0;

    label.select {
      @include font-family-3();
      font-size: 30px;
      line-height: 36px;
      margin: 0;
    }
  }

  .submit {
    @include make-orange-button();
  }
}
