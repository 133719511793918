.shadow {
  @include elevation-4();
  transition: all 200ms ease 0s;

  &.shadow-hover {
    &:hover {
      @include elevation-12();
    }
  }
}
