@import "colors";
@import "fonts";

$normal: 1rem;
$size-1: 4.4rem;
$size-2: 3.5rem;
$size-3: 2.9rem;
$size-4: 2.5rem;
$size-5: 2rem;
$text-color: #4b3316;

$size-m-1: calc($size-1 / 1.2);
$size-m-2: calc($size-2 / 1.2);
$size-m-3: calc($size-3 / 1.2);
$size-m-4: calc($size-4 / 1.2);
$size-m-5: calc($size-5 / 1.2);

html {
}

strong {
  font-weight: bold;
}

body {
  @include font-family-1();
  color: $text-color;
  font-size: 14px;
  line-height: 1.42857143;
}

h1, h2 {
  @include font-family-2();
  color: $text-color;
  margin: 0 0 1rem;
}

h3, h4, h5 {
  @include font-family-3();
  margin: 0 0 0.8rem;
  font-weight: bold;
}

h1 {
  font-size: $size-1;
  line-height: $size-1;
  font-weight: normal;
  @include respond-below(md) {
    font-size: $size-m-1;
    line-height: $size-m-1;
  }
}

h2 {
  font-size: $size-2;
  line-height: $size-2;
  @include respond-below(md) {
    font-size: $size-m-2;
    line-height: $size-m-2;
  }
}

h3 {
  font-size: $size-3;
  line-height: $size-3;
  @include respond-below(md) {
    font-size: $size-m-3;
    line-height: $size-m-3;
  }
}

h4 {
  font-size: $size-4;
  line-height: $size-4;
  font-weight: normal;
  margin: 0 0 1rem;
  @include respond-below(md) {
    font-size: $size-m-4;
    line-height: $size-m-4;
  }
}

h5 {
  font-size: $size-5;
  line-height: $size-5;
  @include respond-below(md) {
    font-size: $size-m-5;
    line-height: $size-m-5;
  }
}

/*strong {
  font-family: "LUELLA Black";
  font-weight: normal;
  font-size: 28px;
  line-height: 36px;
}*/

hr {
  height: 8px;
  background: url('/files/build/img/hr.png') center center no-repeat;
  background-size: cover;
  border: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.ce_headline {
  hr {
    background-size: contain;
    margin-top: 25px;
  }
}

a {
  color: $text-color;

  &:hover {
    color: #d10b11;
    text-decoration: none;
  }
}

button, .button, .search-field input {
  @include font-family-3();
  font-weight: 900;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.nav-mobile input {
  font-size: 2rem;
}

nav {
  a {
    text-decoration: none;
    @include font-family-2();
    font-size: $size-4;
  }
}

.nav-desktop {
  a {
    font-size: $size-5;
  }
}

a:hover > article,
a:hover > .box {
  color: $text-color;
}

.teaser {
  hr {
    width: 61.3%;
    margin: 0;
  }

  &.teaser-wide {
    h2 {
      @include respond-below(lg) {
        font-size: $size-2;
      }
    }
  }

  &.teaser-tall {
  }
}

.ce_headline {
  .subtitle {
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
}

.article {
  font-size: 16px;

  h1, h2, h3, h4, h5 {
    margin-bottom: 1.6rem;
  }

  a {
    text-decoration: underline;

    &:hover {
      color: $red;
    }
  }
}

a.active {
  color: $red;
}

.text-white {
  color: $white !important;
  text-shadow: 1px 0px 6px #000;

  h1, h2, h3, h4, h5 {
    color: $white !important;
  }

  hr {
    background-image: url('/files/build/img/hr-white.png') !important;
  }

  a {
    color: $white;
    text-decoration: underline;
  }

  .teaser {
    a {
      text-decoration: none;
    }
  }
}

.text-white-no-shadow {
  color: $white !important;

  h1, h2, h3, h4, h5 {
    color: $white !important;
  }

  hr {
    background-image: url('/files/build/img/hr-white.png') !important;
  }

  a {
    color: $white;
    text-decoration: underline;
  }

  .teaser {
    a {
      text-decoration: none;
    }

    .button {
      color: #f5b339 !important;
    }
  }
}

.text-orange {
  color: $orange !important;

  h1, h2, h3, h4, h5, h6 {
    color: $orange !important;
  }
}

.teaser {
  .teaser-text h2 {
    font-size: $size-3;
    line-height: $size-3;
  }

  &.teaser-wide {
    @include respond-above(md) {
      .teaser-text h2 {
        font-size: $size-2;
        line-height: $size-2;
      }
    }
  }
}

// TABLE (e.g. inside Modal)
article {
  table {
    margin-bottom: 1rem;

    td {
      padding-left: .5rem;
      padding-right: .5rem;
      padding-bottom: .5rem;
    }

    p {
      margin: 0 !important;
    }

    ul {
      padding-left: 1rem;
    }
  }
}
