@import "functions";

$use-fadeIn: true;
@import './../../../node_modules/animate-scss/animate.scss';

.invisible {
  display: none !important;
}

html, body {
  height: 100%;
}

body {
  background: none;
}

/* Make the grid gutter also between the columns horizontal */
.row {
  & > * {
    padding-top: calc($grid-gutter-width / 2);
    padding-bottom: calc($grid-gutter-width / 2);
  }
}

/* Special col formatting for 1/5 */
.col-2_4 {
  @include make-col-ready();
  @include make-col(2.4);
}

.col-md-2_4 {
  @include respond-above(md) {
    @include make-col-ready();
    @include make-col(2.4);
  }
}

.col-lg-2_4 {
  @include respond-above(lg) {
    @include make-col-ready();
    @include make-col(2.4);
  }
}

.col-4_8 {
  @include make-col-ready();
  @include make-col(4.8);
}

.col-md-4_8 {
  @include respond-above(md) {
    @include make-col-ready();
    @include make-col(4.8);
  }
}

.col-lg-4_8 {
  @include respond-above(lg) {
    @include make-col-ready();
    @include make-col(4.8);
  }
}

.col-7_2 {
  @include make-col-ready();
  @include make-col(7.2);
}

.col-md-7_2 {
  @include respond-above(md) {
    @include make-col-ready();
    @include make-col(7.2);
  }
}

.col-lg-7_2 {
  @include respond-above(lg) {
    @include make-col-ready();
    @include make-col(7.2);
  }
}

.col-9_6 {
  @include make-col-ready();
  @include make-col(9.6);
}

.col-md-9_6 {
  @include respond-above(md) {
    @include make-col-ready();
    @include make-col(9.6);
  }
}

.col-lg-9_6 {
  @include respond-above(lg) {
    @include make-col-ready();
    @include make-col(9.6);
  }
}

body {
  display: flex;
  flex-direction: column;
}

.mod_article {
  @include fadeIn();

  .container, .container-fluid {
    padding-top: 40px;
    padding-bottom: 40px;

    & > div:not(.row) {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }

  &.last {
    flex: 1 0 auto;
  }
}

.footer {
  flex-shrink: 0;
}


.ce_headline, .ce_text {
  @include make-col-ready();
}

.h-100 {
  height: 100% !important;
}

.container-large {
  max-width: 1440px !important;
}

.ce_form {
  @include make-col-ready();
  @include make-col(12);
}