@import "functions";
@import "typography";

$use-fadeIn: true;
@import './../../../node_modules/animate-scss/animate.scss';

html {
  /* For desktop */
}

body {

  &.has-nav-desktop {
    padding-top: 60px;

    @include respond-above(lg) {
      padding-top: 120px;
    }

    &.has-subnav-desktop {
      @include respond-above(lg) {
        padding-top: 212px;
      }
    }
  }
}

.nav-desktop {
  position: fixed;
  top: 0;
  width: 100%;
  height: 130px;
  background-image: url('/files/build/img/background_navbar.jpg');
  overflow: visible;
  z-index: 999;

  .col, .col-5 {
    padding-top: 0;
    padding-bottom: 0;
  }

  a:hover {
    color: #fff;
    text-shadow: 0 0 4px #efd4a8, 0 0 4px #efd4a8, 0 0 4px #efd4a8;
  }

  .nav-desktop-row {
    height: 60px;
  }

  .nav-desktop-search {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    form {
      margin: 0;

      .control {
        display: inline-flex;
        justify-content: flex-end;
        align-items: flex-end;

      }
    }
  }

  .nav-desktop-left {
    height: 100%;
    width: 100%;

    #header_left, .inside {
      height: 100%;
      width: 100%;
    }

    .mod_customnav {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      a {
        padding: 0 0.5rem;
        margin-right: 2.5rem;
      }
    }
  }

  .nav-desktop-right {
    height: 100%;
    width: 100%;

    #header_right, .inside {
      height: 100%;
      width: 100%;
    }

    .mod_customnav {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      a {
        padding: 0 0.5rem;
        margin-left: 4rem;
      }
    }
  }

  .logo-wrapper {
    position: absolute;
    left: 50%;
    margin-left: -90px;
    z-index: 101;

    .logo {
      position: absolute;
      width: 206px;
      height: 160px;
      top: 0;
      left: 0;
      overflow: visible;
      z-index: 1000;
    }
  }

  .leaf-left {
    position: absolute;
    left: 0;
    top: 0;
    @media (max-width: 1390px) {
      top: -20px;
      margin-left: -100px;
    }
    @include respond-below(md) {
      display: none;
    }
  }

  .leaf-middle {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -180px;

    @include respond-below(md) {
      display: none;
    }
  }

  .leaf-right {
    position: absolute;
    right: 0;
    top: 0;
    @media (max-width: 1390px) {
      margin-right: -70px;
    }
    @include respond-below(md) {
      display: none;
    }
  }
}

.nav-mobile {
  display: block;
  position: fixed;

  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;

  .navbar-brand {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-image: url('/files/build/img/background_navbar.jpg');
    background-size: cover;
    height: 60px;
    z-index: 999;

    .toggle-search {
      font-size: 1.9rem;
      height: 38px;
      width: 33px;
      margin: 0 1rem;
    }

    .logo {
      position: absolute;
      top: 0;
      left: calc(50% - 51px);
      width: 103px;
      height: 80px;
      z-index: 100;
    }

    .toogle-menu {
      position: relative;
      width: 51px;
      height: 51px;
      margin: 0 1rem;
    }
  }

  .nav-mobile-dropdown-menu {
    &.bounceInDown {
      @include bounceInDown();
    }

    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 333;
    background-color: white;
    padding-top: 60px;

    .mod_navigation {
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      height: 100%;
      /* The main point: */
      overflow-y: auto;
      /* Optional but highly reccomended: enables momentum scrolling on iOS */
      -webkit-overflow-scrolling: touch;

      text-align: center !important;

      > .navbar-item {
        display: block;
        width: 100%;
        padding: 1rem 3rem;
      }

      .dropdown-toggle {
        padding-bottom: 0.2rem;

        &::after {
          display: inline-block;
          margin-left: .255em;
          border-top: .3em solid;
          border-right: .3em solid transparent;
          border-bottom: 0;
          border-left: .3em solid transparent;
          width: 0;
          height: 0;
          vertical-align: .255em;
          content: "";
        }
      }

      .navbar-dropdown {
        display: none;

        &.show {
          display: block;
        }

        a {
          display: block;
          font-size: 1.6rem;
          padding: 0.2rem 3rem;
          width: 100%;
        }
      }
    }
  }
}

.subnav {
  width: 100%;
  padding: 40px 20px 20px;
  text-align: center;

  a {
    @include font-family-2();
    font-size: 23px;
    padding: 0px 20px;
  }

  notselect {
    margin-top: -20px;
    width: 100%;
    cursor: pointer;
    outline: none;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIwLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA0LjkgMTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQuOSAxMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiM0NDQ0NDQ7fQo8L3N0eWxlPgo8dGl0bGU+YXJyb3dzPC90aXRsZT4KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxLjQsNC43IDIuNSwzLjIgMy41LDQuNyAiLz4KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIzLjUsNS4zIDIuNSw2LjggMS40LDUuMyAiLz4KPC9zdmc+Cg==) no-repeat 98% center;
    background-color: rgba(0, 0, 0, 0);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
    display: block;
    font-size: 20px;
    padding: 10px;
    color: #000;
    background-color: #FFF;
    border: 1px solid #CCC;
    @include font-family-1();
  }
}

.subnav-desktop, .subnav-mobile {
  position: absolute;
  height: 92px;
  top: 60px;

  z-index: 333;

  @include respond-above(lg) {
    position: fixed;
    top: 120px;
  }
}
