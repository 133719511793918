@import "colors";

.mod_newslist {
  padding: 50px 20px;
}

.article {
  .news {
    a {
      color: $orange;
      text-decoration: none;
    }

    p {
      margin-bottom: 1rem;
    }
  }

  .news-short {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    border-bottom: 1px solid $grey-light;

    &:first-of-type {
      padding-top: 0 !important;
    }

    &:last-of-type {
      border-bottom: none !important;
    }

    h5 {
      margin-bottom: 0;
    }
  }
}